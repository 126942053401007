import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { WindowRef } from '../window.service';
import { TrackerService } from '../tracker.service';
import { Sport } from '../tracker-widgets/tracker-widgets.component';

@Component({
  selector: 'app-tracker-widget-v2',
  templateUrl: './tracker-widget-v2.component.html',
  styleUrls: ['./tracker-widget-v2.component.scss']
})
export class TrackerWidgetV2Component implements OnInit, OnDestroy {
  allSports: number[] = [];
  sportNumber = null;

  public sportId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public trackerState = false;
  public hasMatch = false;

  private _timeoutCallback: number;

  matchId: Observable<{ sport?: number, matchId?: number }>;
  // tslint:disable-next-line:variable-name
  private _matchId: BehaviorSubject<{ sport?: number, matchId?: number }> = new BehaviorSubject({ sport: 0, matchId: null });

  constructor(
    private winRef: WindowRef,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private trackerService: TrackerService,
    private renderer: Renderer2,
  ) {
    this.matchId = this._matchId.asObservable();

    this.renderer.addClass(document.body, 'page-tracker');

    if (Sport) {
      for (const n in Sport) {
        if (!isNaN(+n)) {
          this.allSports.push(+n);
        }
      }
    }

    setTimeout(() => {
      console.log(this.sportNumber, this.hasMatch);
      if (this.hasMatch === false) {
        this.winRef.nativeWindow.parent.postMessage({sport: this.sportNumber, code: 404}, '*');
      }
    }, 5000);
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.sportId.next(data.sport);
      this.sportNumber = data.sport;

      this.initWidget();

      this.matchId.subscribe((info: { sport?: number, matchId?: number }) => {
         // this.hasMatch = !!info;
        if (!!info && info.matchId !== -1) {
          this.winRef.nativeWindow.SIR('addWidget', '#sr-widget-tracker-' + info.sport,
            'match.lmtPlus', {layout: 'leftright', matchId: info.matchId}
          );
        }
      });
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'page-tracker');

    // TODO check to destroy Widgets
    this.winRef.nativeWindow.SIR('removeWidget', '#sr-widget-matchlist');
    this.winRef.nativeWindow.SIR('removeWidget', '#sr-widget-tracker-' + this.sportId.getValue());
    this.winRef.nativeWindow.SIR('removeWidget', '#sr-widget-meta-' + this.sportId.getValue());
  }

  public initWidget() {
    if (!this.winRef.nativeWindow.SIR) {
      // tslint:disable-next-line:typedef
      ((a, b, c, d, e, f, g?: any, h?: any, i?: any) => {
        // tslint:disable-next-line:no-unused-expression
        a[e] || (i = a[e] = () => {
          // @ts-ignore
          (a[e].q = a[e].q || []).push(arguments);
        },
          i.l = new Date(), i.o = f,
          g = b.createElement(c), h = b.getElementsByTagName(c)[0], g.async = 1, g.src = d,
          g.setAttribute('n', e), h.parentNode.insertBefore(g, h));
      })(window, document, 'script',
        'https://widgets.sir.sportradar.com/af643e8196597713d5019cdf946eaca5/widgetloader', 'SIR', {
          language: 'en',
          theme: 'custom',
        });
    }

    this.winRef.nativeWindow.SIR('addWidget', '#sr-widget-meta-' + this.sportNumber, 'meta.hottestmatchselector',
      {
        sportId: this.sportNumber,
        onMatchSelect: (id: number) => {
          this._matchId.next({sport: this.sportNumber, matchId: id});
          this.trackerService.addHottestMatch(this.sportNumber, id);
          try {
            clearTimeout(this._timeoutCallback);
            window.parent.postMessage({sport: this.sportNumber, code: 200}, '*');
            this.hasMatch = true;
          } catch (e) {
          }
        }
      });

    this._timeoutCallback = setTimeout(() => {
      if (this._matchId.getValue().matchId === null && this.hasMatch == false) {
        this._matchId.next({sport: this.sportNumber, matchId: -1});
        try {
          window.parent.postMessage({sport: this.sportNumber, code: 404}, '*');
        } catch (e) {
        }
      }
    }, 5000)
  }
}
