import { Injectable } from '@angular/core';
import { Sport } from './tracker-widgets/tracker-widgets.component';

export interface HottestMatches {
  sport: Sport;
  matchId: number;
}

@Injectable({
  providedIn: 'root'
})
export class TrackerService {

  keepHottestMatches: HottestMatches[] = [];

  constructor() { }

  public addHottestMatch(sport: Sport, matchId: number) {
    this.keepHottestMatches.push({sport, matchId});
  }

  public getHottestMatch(sport: Sport) {
    return this.keepHottestMatches.find(v => v.sport === sport);
  }
}
