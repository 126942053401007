import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { WindowRef } from '../window.service';
import { TrackerService } from '../tracker.service';

export enum Sport {
  football = 1,
  basketball = 2,
  americanFootball = 16,
  tennis = 5,
  hockey = 4,
  baseball = 3
}

export interface SportSpec {
  slug: string;
  label: string;
}

@Component({
  selector: 'app-tracker-widgets',
  templateUrl: './tracker-widgets.component.html',
  styleUrls: ['./tracker-widgets.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TrackerWidgetsComponent implements OnInit, AfterViewInit, OnDestroy {

  allSports: number[] = [];

  public sportId: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public matchlistState = true;
  public trackerState = false;

  matchId: Observable<{ sport?: number, matchId?: number }>;
  // tslint:disable-next-line:variable-name
  private _matchId: BehaviorSubject<{ sport?: number, matchId?: number }> = new BehaviorSubject(null);

  constructor(private winRef: WindowRef,
              private ref: ChangeDetectorRef,
              private route: ActivatedRoute,
              private trackerService: TrackerService,
              private renderer: Renderer2) {
    this.matchId = this._matchId.asObservable();

    this.renderer.addClass(document.body, 'page-tracker');

    if (Sport) {
      for (const n in Sport) {
        if (!isNaN(+n)) {
          this.allSports.push(+n);
        }
      }
    }
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.sportId.next(data.sport);

      this.initWidget();
      this.winRef.nativeWindow.SIR('addWidget', '#sr-widget-matchlist', 'match.matchList',
        {
          sportId: data.sport,
          isLive: false,
          onItemClick: (type: any, obj: any) => {
            this._matchId.next({sport: data.sport, matchId: obj.matchId});
          }
        });

      this.matchId.subscribe((info: { sport?: number, matchId?: number }) => {
        if (!info) {
          info = this.trackerService.getHottestMatch(this.sportId.getValue());
        } else {
          window.scrollTo(0, 0);

          if (this.winRef.nativeWindow.innerWidth < 992) {
            this.matchlistState = false;
            this.trackerState = true;
          }
        }

        if (info) {
          let widgetType = 'match.lmtExtended';
          if (info.sport !== 1) {
            widgetType = 'match.lmtPlus';
          }

          this.winRef.nativeWindow.SIR('addWidget', '#sr-widget-tracker-' + info.sport,
            widgetType, {matchId: info.matchId}
          );
        }
      });
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'page-tracker');

    // TODO check to destroy Widgets
    this.winRef.nativeWindow.SIR('removeWidget', '#sr-widget-matchlist');
    this.winRef.nativeWindow.SIR('removeWidget', '#sr-widget-tracker-' + this.sportId.getValue());
    this.winRef.nativeWindow.SIR('removeWidget', '#sr-widget-meta-' + this.sportId.getValue());
  }


  ngAfterViewInit() {
  }

  public initWidget() {
    if (!this.winRef.nativeWindow.SIR) {
      // tslint:disable-next-line:typedef
      ((a, b, c, d, e, f, g?: any, h?: any, i?: any) => {
        // tslint:disable-next-line:no-unused-expression
        a[e] || (i = a[e] = () => {
          // @ts-ignore
          (a[e].q = a[e].q || []).push(arguments);
        },
          i.l = new Date(), i.o = f,
          g = b.createElement(c), h = b.getElementsByTagName(c)[0], g.async = 1, g.src = d,
          g.setAttribute('n', e), h.parentNode.insertBefore(g, h));
      })(window, document, 'script',
        'https://widgets.sir.sportradar.com/af643e8196597713d5019cdf946eaca5/widgetloader', 'SIR', {
          language: 'en',
          theme: 'custom',
        });
    }

    this.allSports.forEach(idSport => {
      this.winRef.nativeWindow.SIR('addWidget', '#sr-widget-meta-' + idSport, 'meta.hottestmatchselector',
        {
          sportId: idSport,
          onMatchSelect: (id: number) => {
            this._matchId.next({sport: idSport, matchId: id});
            this.trackerService.addHottestMatch(idSport, id);
          }
        });
    });
  }

  toggle() {
    this.matchlistState = !this.matchlistState;
    this.trackerState = !this.trackerState;
  }

}
