import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Sport, TrackerWidgetsComponent } from './tracker-widgets/tracker-widgets.component';
import { TrackerWidgetV2Component } from './tracker-widget-v2/tracker-widget-v2.component';

const routes: Routes = [
  {
    path: 'football',
    data: {
      sport: Sport.football
    },
    component: TrackerWidgetsComponent
  },
  {
    path: '1',
    data: {
      sport: Sport.football
    },
    component: TrackerWidgetsComponent
  },
  {
    path: 'basketball',
    data: {
      sport: Sport.basketball
    },
    component: TrackerWidgetsComponent
  },
  {
    path: '2',
    data: {
      sport: Sport.basketball
    },
    component: TrackerWidgetsComponent
  },
  {
    path: 'american-football',
    data: {
      sport: Sport.americanFootball
    },
    component: TrackerWidgetsComponent
  },
  {
    path: '16',
    data: {
      sport: Sport.americanFootball
    },
    component: TrackerWidgetsComponent
  },
  {
    path: 'tennis',
    data: {
      sport: Sport.tennis
    },
    component: TrackerWidgetsComponent
  },
  {
    path: '5',
    data: {
      sport: Sport.tennis
    },
    component: TrackerWidgetsComponent
  },
  {
    path: 'hockey',
    data: {
      sport: Sport.hockey
    },
    component: TrackerWidgetsComponent
  },
  {
    path: '4',
    data: {
      sport: Sport.hockey
    },
    component: TrackerWidgetsComponent
  },
  {
    path: 'baseball',
    data: {
      sport: Sport.baseball
    },
    component: TrackerWidgetsComponent
  },
  {
    path: '3',
    data: {
      sport: Sport.baseball
    },
    component: TrackerWidgetsComponent
  },
  {
    path: 'v2/football',
    data: {
      sport: Sport.football
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/1',
    data: {
      sport: Sport.football
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/basketball',
    data: {
      sport: Sport.basketball
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/2',
    data: {
      sport: Sport.basketball
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/american-football',
    data: {
      sport: Sport.americanFootball
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/16',
    data: {
      sport: Sport.americanFootball
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/tennis',
    data: {
      sport: Sport.tennis
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/5',
    data: {
      sport: Sport.tennis
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/hockey',
    data: {
      sport: Sport.hockey
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/4',
    data: {
      sport: Sport.hockey
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/baseball',
    data: {
      sport: Sport.baseball
    },
    component: TrackerWidgetV2Component
  },
  {
    path: 'v2/3',
    data: {
      sport: Sport.baseball
    },
    component: TrackerWidgetV2Component
  },
  {
    path: '**',
    redirectTo: '/football',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
