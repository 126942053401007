import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'live-tracker-app';

  constructor(private router: Router) {
    // if (window.addEventListener) {
    //   window.addEventListener('message', this.receiveMessage, false);
    // } else {
    //   (window as any).attachEvent('onmessage', this.receiveMessage);
    // }
  }
}
